import React from "react";
import "./ServiceSection3.css";
function ServiceSection3() {
  return (
    <>
      <div className="ServiceSection3MainDiv">
        <div className="ServiceSection3">
          <div className="ServiceSection3Div1">
            <h2>We Deliver a Full Agency Experience</h2>
          </div>
          <div className="ServiceSection3Div2">
            <div className="serviceSection3Card">
              <div className="serviceSection3CardImage">
                <img
                  src={require("../../../../assets/ServicePage/ServiceSection3/image1.png")}
                  alt=""
                />
              </div>
              <div className="serviceSection3CardContent">
                <h4>Creative Concepts & Ideas</h4>
                <p>
                  At <span>Navastha Infotech</span>, we pride ourselves on our
                  ability to think outside the box and deliver creative concepts
                  that push the boundaries of technology. Our innovative
                  approach ensures that every project we undertake is infused
                  with fresh, cutting-edge ideas tailored to meet the unique
                  needs of our clients.
                </p>
              </div>
            </div>
            <div className="serviceSection3Card">
              <div className="serviceSection3CardImage">
                <img
                  src={require("../../../../assets/ServicePage/ServiceSection3/image2.png")}
                  alt=""
                />
              </div>
              <div className="serviceSection3CardContent">
                <h4>Branding</h4>
                <p>
                  In today’s competitive market, a strong, cohesive brand is
                  more important than ever. At <span>Navastha Infotech</span>,
                  we help businesses build and refine their brand identity to
                  stand out in the digital landscape. Our branding services
                  encompass everything from logo design and brand messaging to
                  comprehensive brand strategy development.
                </p>
              </div>
            </div>
            <div className="serviceSection3Card">
              <div className="serviceSection3CardImage">
                <img
                  src={require("../../../../assets/ServicePage/ServiceSection3/image3.png")}
                  alt=""
                />
              </div>
              <div className="serviceSection3CardContent">
                <h4>UX & UI Design & Analysis</h4>
                <p>
                  User experience and user interface design are critical
                  components of any successful digital product. At
                  <span>Navastha Infotech</span>, we specialize in creating
                  intuitive, engaging, and visually appealing designs that
                  enhance user satisfaction and drive engagement. Our UX/UI
                  design process begins with thorough research and analysis to
                  understand user behavior and preferences.
                </p>
              </div>
            </div>
            <div className="serviceSection3Card">
              <div className="serviceSection3CardImage">
                <img
                  src={require("../../../../assets/ServicePage/ServiceSection3/image4.png")}
                  alt=""
                />
              </div>
              <div className="serviceSection3CardContent">
                <h4>Ecommerce</h4>
                <p>
                  Ecommerce is more than just selling products online; it’s
                  about creating a seamless, enjoyable shopping experience that
                  keeps customers coming back. At <span>Navastha Infotech</span>
                  , we offer comprehensive ecommerce solutions that cover
                  everything from website design and development to payment
                  gateway integration and security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceSection3;
