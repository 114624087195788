import React from "react";
import "./ContactSection3.css";

function ContactSection3() {
  return (
    <>
      <div className="responsiveMap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d222.3596675943128!2d75.72511906982822!3d26.91127965520113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1718274908119!5m2!1sen!2sin"
          width="600"
          height="450"
          allowFullScreen
          title="gmap"
          referrerPolicy="no-referrer-when-downgrade"
          className="iframeMap"
        ></iframe>
      </div>
    </>
  );
}

export default ContactSection3;
