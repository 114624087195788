import React from "react";
import "./ServiceSection2.css";
import { FaIcons } from "react-icons/fa";
function ServiceSection2() {
  return (
    <>
      <div className="ServiceSection2">
        <div className="ServiceSection2Div1">
          {/* <a href="/">Advanced Technology Services</a> */}
          <h2>Sales Strategy and Conversion Optimization</h2>
          <ol>
            <li>
              <span> Custom Software Development: </span>
              Designing and developing tailored software solutions to meet your
              business needs and enhance operational efficiency.
            </li>
            <li>
              <span> IT Consulting Services: </span>
              Providing expert advice and strategic planning to optimize your IT
              infrastructure and support your business goals.
            </li>
            <li>
              <span> Mobile App Development: </span>
              Developing intuitive and feature-rich mobile applications for both
              iOS and Android platforms.
            </li>
            <li>
              <span> Technical Support: </span>
              Providing round-the-clock technical support to resolve issues
              swiftly and keep your systems running smoothly.
            </li>
          </ol>
        </div>

        <div className="ServiceSection2Div2">
          <div>
            <img
              src={require("../../../../assets/ServicePage/ServiceSection2/image.png")}
              alt=""
            />
          </div>
          <div className="ServiceSection2Div2Card">
            <img
              src={require("../../../../assets/ServicePage/ServiceSection2/iconbg.png")}
              alt=""
              style={{ height: "80px", width: "80px" }}
            />
            <h4>Performance Marketing</h4>
            <p>
              The IT sector continues to experience robust growth, driven by
              increasing digital transformation across industries. Companies are
              investing heavily in technology to enhance efficiency, improve
              customer experiences, and stay competitive.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceSection2;
