import React from "react";
import "./AboutSection4.css";
import {
  FaAd,
  FaBullhorn,
  FaChartBar,
  FaChartLine,
  FaLightbulb,
  FaShoppingCart,
} from "react-icons/fa";
function AboutSection4() {
  return (
    <>
      <div className="AboutSection4">
        <h2>We Deliver Results</h2>
        <div className="grid-container">
          <div className="grid-item">
            <div
              style={{
                fontSize: "50px",
                marginBottom: "10px",
                color: "#333",
              }}
            ></div>
            <div className="aboutSection4Icon">
              <FaChartLine />
            </div>
            <h4>Software Development</h4>
            <p>
              Custom software solutions tailored to meet the unique needs of
              your business.
            </p>
          </div>
          <div className="grid-item">
            <div className="aboutSection4Icon">
              <FaBullhorn />
            </div>
            <h4>IT Consulting</h4>
            <p>
              Expert advice and strategies to optimize your IT infrastructure
              and operations.
            </p>
          </div>
          <div className="grid-item">
            <div className="aboutSection4Icon">
              <FaLightbulb />
            </div>
            <h4>Creative Concepts & Ideas</h4>
            <p>
              Our team excels in finding unconventional solutions to complex
              challenges, turning obstacles into opportunities.
            </p>
          </div>
          <div className="grid-item">
            <div className="aboutSection4Icon">
              <FaShoppingCart />
            </div>
            <h4>Ecommerce</h4>
            <p>
              Designing and developing bespoke eCommerce solutions tailored to
              meet the specific needs and goals of your business.
            </p>
          </div>
          <div className="grid-item">
            <div className="aboutSection4Icon">
              <FaChartBar />
            </div>
            <h4>UX & UI Design & Analysis</h4>
            <p>
              Ensuring that eCommerce platforms are fully responsive, providing
              a seamless shopping experience across all devices and screen
              sizes.
            </p>
          </div>
          <div className="grid-item">
            <div className="aboutSection4Icon">
              <FaAd />
            </div>
            <h4>Digital Advertising</h4>
            <p>
              Implementing robust product management features, including
              inventory management, product categorization, and advanced search
              functionalities.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection4;
