import React from "react";
import "./Container.css";
import { FaSearchPlus, FaChartLine, FaGift } from "react-icons/fa";

function Container() {
  return (
    <>
      <div className="MainContainer">
        <div className="services-container">
          <div className="service">
            <FaSearchPlus className="service-icon" />
            <div className="service-content">
              <h3>Research And Discovery</h3>
            </div>
          </div>
          <div className="divider"></div>
          <div className="service">
            <FaChartLine className="service-icon" />
            <div className="service-content">
              <h3>Planning For The Future</h3>
            </div>
          </div>
          <div className="divider"></div>
          <div className="service">
            <FaGift className="service-icon" />
            <div className="service-content">
              <h3>Building Your Brand</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Container;
