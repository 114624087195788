import React, { useState } from "react";
import "./Section6.css";

function Section6() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    window.location.href = `mailto:info@navasthainfotech.com?subject=${formData.subject}&body=Hi, My name is :${formData.name} 
                Contact N:${formData.contact} and My email id is :${formData.email} and ${formData.message} `;
  };

  return (
    <>
      <div className="sec6MainDiv">
        <div className="sec6Heading">
          <h2>Get In Touch</h2>
          <h6>Kick Start Your Awesome Project Now!</h6>
          <p>
            We’d love to hear from you! Whether you’re looking for a specific IT
            solution or just want to learn more about how we can help your
            business thrive, don’t hesitate to contact us.
          </p>
        </div>
        <div className="sec6FormDiv">
          <form className="sec6Form" onSubmit={handleSubmitForm}>
            <div className="sec6Input">
              <div>
                <input
                  name="name"
                  value={formData.name}
                  type="text"
                  placeholder="Your name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  name="email"
                  value={formData.email}
                  type="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="sec6TextArea">
                <textarea
                  style={{
                    border: "none",
                    color: "white",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
                  }}
                  name="message"
                  value={formData.message}
                  placeholder="Write your message"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="sec6Button">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Section6;
