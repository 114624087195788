import React from "react";
import "./AboutSection2.css";
function AboutSection2() {
  return (
    <>
      <section class="AboutSec2MainDiv">
        <div className="AboutSec2Div">
          <div class="AboutSec2text-content">
            <h1>We are increasing business success with technology</h1>
            <p>
              Our expertise ensures the smooth integration of new technologies
              with existing systems, minimizing disruption and maximizing
              productivity.
            </p>
            <div class="progress-bar">
              <span>Business Security</span>
              <div class="progress">
                <div class="progress-fill" style={{ width: "86%" }}></div>
              </div>
              <span>86%</span>
            </div>
            <div class="progress-bar">
              <span>Career Development</span>
              <div class="progress">
                <div class="progress-fill" style={{ width: "72%" }}></div>
              </div>
              <span>72%</span>
            </div>
            <div class="progress-bar">
              <span>Business Innovation</span>
              <div class="progress">
                <div class="progress-fill" style={{ width: "95%" }}></div>
              </div>
              <span>95%</span>
            </div>
          </div>
          <div class="AboutSec2Image">
            <img
              src={require("../../../../assets/AboutPage/AboutSection2/image.png")}
              alt="Business Success"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection2;
