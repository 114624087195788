import React, { useEffect, useState } from "react";
import "./Section7.css";
import { Slide } from "react-slideshow-image";

function Section7() {
  const [slideValue, setSlideValue] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSlideValue(1);
      } else {
        setSlideValue(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="sec7sliderHandler">
        <div className="sec7MainDivSlider">
          <div className="sec7Heading">
            <h1>Our Blog</h1>
            <h3>News and Thoughts</h3>
          </div>
          <div className="sec7CardDiv">
            <Slide
              // slidesToShow={slideValue}
              slidesToShow={slideValue}
              duration={2000}
              autoplay={true}
              infinite={true}
              className="HomeSec7Slider"
            >
              <div className="sec7CardContainer">
                <div className="sec7Card">
                  <div className="sec7ImgDiv">
                    <img
                      className="HomeSec7Img"
                      alt="BrandLogo"
                      src={require("../../../../assets/ServicePage/ServiceSection3/image3.png")}
                    />
                  </div>
                  <div className="sec7CardContent">
                    <span>Technology</span>
                    <span>August 10, 2023</span>
                    <a href="/blog">
                      <h6 className="blogLink">UX & UI Design & Analysis</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div className="sec7CardContainer">
                <div className="sec7Card">
                  <div className="sec7ImgDiv">
                    <img
                      className="HomeSec7Img"
                      alt="BrandLogo"
                      src={require("../../../../assets/HomePage/section7/img1.jpg")}
                    />
                  </div>
                  <div className="sec7CardContent">
                    <span>Technology</span>
                    <span>August 10, 2023</span>
                    <a href="/blog">
                      <h6 className="blogLink">
                        The act of knowledge & the act of design thinking
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
              <div className="sec7CardContainer">
                <div className="sec7Card">
                  <div className="sec7ImgDiv">
                    <img
                      className="HomeSec7Img"
                      alt="BrandLogo"
                      src={require("../../../../assets/ServicePage/ServiceSection3/image1.png")}
                    />
                  </div>
                  <div className="sec7CardContent">
                    <span>Design</span>
                    <span>March 9, 2023</span>
                    <a href="/blog">
                      <h6 className="blogLink">Creative Concepts & Ideas</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div className="sec7CardContainer">
                <div className="sec7Card">
                  <div className="sec7ImgDiv">
                    <img
                      className="HomeSec7Img"
                      alt="BrandLogo"
                      // src={require("../../../../assets/HomePage/section7/img3.jpg")}
                      src={require("../../../../assets/ServicePage/ServiceSection2/image.png")}
                    />
                  </div>
                  <div className="sec7CardContent">
                    <span>Performance Marketing</span>
                    <span>Mar 12, 2023</span>
                    <a href="/blog">
                      <h6 className="blogLink">
                        Proactive customer experience in the business
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section7;
