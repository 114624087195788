import React from "react";
import "./Home.css";
import TextOnImage from "../../components/atom/HomePage/HomeText/TextOnImage";
import Container from "../../components/atom/HomePage/container/Container";
import BrandLogo from "../../components/atom/HomePage/BrandLogo/BrandLogo";
import Section1 from "../../components/organism/HomeSection/Section1/Section1";
import Section2 from "../../components/organism/HomeSection/Section2/Section2";
import Section3 from "../../components/organism/HomeSection/Section3/Section3";
import Section4 from "../../components/organism/HomeSection/Section4/Section4";
import Section5 from "../../components/organism/HomeSection/Section5/Section5";
import Section6 from "../../components/organism/HomeSection/Section6/Section6";
import Section7 from "../../components/organism/HomeSection/Section7/Section7";
// import Effect from "../../components/molecule/Effects/Effectss";

function Home() {
  return (
    <>
    {/* <div>
      <Effect />
    </div> */}
      <div className="Div">
        <div className="MainDiv">
          <TextOnImage />
          <Container />
        </div>
        <div>
          <BrandLogo />
        </div>
        <div>
          <Section1 />
        </div>
        <div>
          <Section2 />
        </div>
        <div>
          <Section3 />
        </div>
        <div>
          <Section4 />
        </div>
        <div>
          <Section5 />
        </div>
        <div>
          <Section6 />
        </div>
        <div>
          <Section7 />
        </div>
      </div>
    </>
  );
}

export default Home;
