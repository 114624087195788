import React from "react";
import "./About.css";
import AboutSection1 from "../../components/organism/AboutSection/Section1/AboutSection1";
import AboutSection2 from "../../components/organism/AboutSection/Section2/AboutSection2";
import AboutSection3 from "../../components/organism/AboutSection/Section3/AboutSection3";
import AboutSection4 from "../../components/organism/AboutSection/Section4/AboutSection4";
import AboutSection5 from "../../components/organism/AboutSection/Section5/AboutSection5";
import AboutSection6 from "../../components/organism/AboutSection/Section6/AboutSection6";
import AboutSection7 from "../../components/organism/AboutSection/Section7/AboutSection7";
function About() {
  return (
    <>
      <AboutSection1 />
      <AboutSection2 />
      {/* <AboutSection3 /> */}
      <AboutSection4 />
      <AboutSection7 />
      {/* <AboutSection5 /> */}
      <AboutSection6 />
    </>
  );
}

export default About;
