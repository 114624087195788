import React from "react";
import "./Contact.css";
import ContactSection2 from "../../components/organism/ContactSection/Section2/ContactSection2";
import ContactSection1 from "../../components/organism/ContactSection/Section1/ContactSection1";
import ContactSection3 from "../../components/organism/ContactSection/Section3/ContactSection3";

function Contact() {
  return (
    <>
      <ContactSection1 />
      <ContactSection2 />
      <ContactSection3 />
    </>
  );
}

export default Contact;
