import React, { useState, useEffect } from "react";
import "./Header.css";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset >= 240;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`header-navbar ${
        scrollDirection === "down" ? "hide" : "show"
      }`}
    >
      <div className="header-navbar-container">
        <div className="logo">
          <img
            alt="logo"
            className="companyLogo"
            src={require("../../../../assets/HomePage/images/Navastha.jpg")}
          />
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={`nav-links ${isMenuOpen ? "show" : ""}`}>
          <li>
            <a href="/">
              <i className="fas fa-home"></i> Home
            </a>
          </li>
          <li>
            <a href="/about">
              <i className="fas fa-info-circle"></i> About
            </a>
          </li>
          <li>
            <a href="/blog">
              <i className="fas fa-concierge-bell"></i> Blog
            </a>
          </li>
          <li>
            <a href="/contact">
              <i className="fas fa-envelope"></i> Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
