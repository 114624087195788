import React from "react";
import "./AboutSection6.css";

function AboutSection6() {
  return (
    <>
      <div className="mainDivSection6">
        <div className="AboutSection6">
          <div className="Aboutsection6Heading">
            <h2>Satisfied Clients</h2>
            <p>
              Our team provides dedicated support and consultation to address
              client needs promptly and effectively, fostering strong, long-term
              relationships.
            </p>
          </div>
          <div className="Aboutsection6Container">
            <div className="section6Icons">
              <img
                style={{ borderRadius: "50px" }}
                width={75}
                alt=""
                src={require("../../../../assets/AboutPage/AboutSection6/images.png")}
              />
            </div>
            <h4>Client Testimonials</h4>
            <p>
              Our clients consistently praise us for our professionalism,
              expertise, and commitment to delivering exceptional results.
            </p>
          </div>
          <div className="Aboutsection6Container">
            <div className="section6Icons">
              <img
                style={{ borderRadius: "50px" }}
                width={75}
                alt=""
                src={require("../../../../assets/AboutPage/AboutSection6/images.png")}
              />
            </div>

            <h4>Client Success</h4>
            <p>
              Our focus on client success drives us to go above and beyond,
              ensuring that every project contributes to our clients' long-term
              achievements.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection6;
