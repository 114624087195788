import React from "react";
import "./Section2.css";

function Section2() {
  return (
    <>
      <div className="sec2MainDiv">
        <div className="sec2Heading">
          <h4>How It Works?</h4>
          <h2>We Are Strategic- Creative Digital Agency.</h2>
        </div>
        <div className="sec2ProjectDiv">
          <div className="sec2Div">
            <h4>Submit Your Design Request</h4>
            <p>
              Share your ideas and requirements with us, and we'll tailor a
              solution to meet your needs.
            </p>
          </div>
          <div className="sec2Div">
            <h4>We Start Working</h4>
            <p>
              Our team of experts begins crafting your project with precision
              and creativity.
            </p>
          </div>
          <div className="sec2Div">
            <h4>We Deliver Excellence !</h4>
            <p>
              Enjoy the final product that not only meets but exceeds your
              expectations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section2;
