import React from "react";
import "./Section5.css";

function Section5() {
  return (
    <>
      <div className="sec5MainDiv">
        <div className="sec5Heading">
          <h1>Lets Start On Your Case Together</h1>
        </div>
        <div className="sec5ImgDiv">
          <img
            className="sec5Img"
            alt="BrandLogo"
            src={require("../../../../assets/HomePage/section5/images.jpg")}
          />
        </div>
      </div>
    </>
  );
}

export default Section5;
