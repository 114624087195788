import React from "react";
import "./Section4.css";

function Section4() {
  return (
    <>
      <div className="HomeSec4MainContainer">
        <div className="Sec4MainDiv">
          <div className="sec4Heading">
            <h2>Why Partner With Us?</h2>
            <h4>What Our Clients Said About Navastha Infotech</h4>
          </div>
          <div className="sec4CardContainer">
            <div className="sec4Card">
              <div className="cardImage">
                <img
                  className="employeImage"
                  src={require("../../../../assets/HomePage/section4/client1.png")}
                  alt="employe"
                />
              </div>

              <div className="sec4CardContentDiv1">
                <p>
                  Navastha Infotech transformed our digital presence with their
                  innovative design solutions. Our user engagement has
                  significantly increased!
                </p>
              </div>
              <div className="sec4CardContentDiv2">
                <h5>Abhishek Rathi</h5>
                <h5>CEO, Vcreatek</h5>
              </div>
            </div>
            <div className="sec4Card">
              <div className="cardImage">
                <img
                  className="employeImage"
                  src={require("../../../../assets/HomePage/section4/client1.png")}
                  alt="employe"
                />
              </div>

              <div className="sec4CardContentDiv1">
                <p>
                  The team at Navastha Infotech delivered beyond our
                  expectations. The new website is user-friendly and visually
                  stunning.
                </p>
              </div>
              <div className="sec4CardContentDiv2">
                <h5>Manish Kumar</h5>
                <h5>CTO, Dentalkart</h5>
              </div>
            </div>
            <div className="sec4Card">
              <div className="cardImage">
                <img
                  className="employeImage"
                  src={require("../../../../assets/HomePage/section4/client1.png")}
                  alt="employe"
                />
              </div>

              <div className="sec4CardContentDiv1">
                <p>
                  Working with Navastha Infotech was a pleasure. Their attention
                  to detail and creativity brought our ideas to life.
                </p>
              </div>
              <div className="sec4CardContentDiv2">
                <h5>Chris Duffes</h5>
                <h5>CEO, Fonbnk.Inc</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section4;
