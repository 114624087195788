import React from "react";
import "./TextOnImage.css";

function TextOnImage() {
  return (
    <>
      <div className="container">
        <div className="ContainerText">
          <h1 className="heading">
            <span className="highlight">Unlock</span>
            <br />
            The Full Potential Of
            <br />
            Your Business.
          </h1>
          <p className="subheading">Creative concepting and visual design</p>
        </div>
      </div>
    </>
  );
}

export default TextOnImage;
