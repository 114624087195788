import React from "react";
import "./ServiceSection1.css";
function ServiceSection1() {
  return (
    <>
      <div className="ServiceSection1">
        <div className="ServiceSec1Content">
          <h2>Transform Your Business with Expert IT Solutions</h2>
        </div>
      </div>
    </>
  );
}

export default ServiceSection1;
