import React, { useState } from "react";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/linkedin";
import "react-social-icons/twitter";
import "./Footer.css";

function Footer() {
  const [email, setEmail] = useState();
  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = `mailto:info@navasthainfotech.com?body=${email}`;
  };

  return (
    <div className="footerContainer">
      <div className="footerDiv1">
        <div className="footerDivContainer" id="companyLogo">
          <a href="/">
            <img
              className="company-logo"
              src={require("../../../../assets/HomePage/images/Navastha.jpg")}
              alt="companion logo"
              style={{ height: "70px", width: "70px" }}
            />
          </a>
          <p>Empowering Innovation at Navastha Infotech</p>
        </div>
        <div className="footerDivContainer" id="footerLinks">
          <h6>Additional links</h6>
          <ul>
            <li>
              <a href="/about">About us</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>

            <li>
              <a href="/contact">Contact us</a>
            </li>
          </ul>
        </div>
        <div className="footerDivContainer" id="footerNews">
          <h6>Latest News</h6>
          <ul>
            <li>
              <p>
                Get the latest latest news, updates, and insights from Navastha
                Infotech.
              </p>
            </li>
          </ul>
        </div>
        <div className="footerDivContainer" id="footerEmail">
          <h6>Subscribe</h6>
          <p>
            Subscribe now and stay informed about our latest articles, tips, and
            promotions.
          </p>
          <form onSubmit={handleClick}>
            <input
              type="text"
              name="email"
              placeholder="your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Go</button>
          </form>
        </div>
      </div>
      <div className="footerDiv2">
        <div className="social-icon">
          <SocialIcon
            style={{ width: "40px", height: "40px" }}
            url="https://www.linkedin.com/company/navastha-infotech/"
          />
          <SocialIcon
            style={{ width: "40px", height: "40px" }}
            url="https://twitter.com/Navasthainfo"
          />
        </div>
        <p>
          © 2024 Navastha Infotech - All Rights Reserved | Developed by Navastha Infotech Pvt. Ltd.
        </p>
      </div>
    </div>
  );
}

export default Footer;
