import React from "react";
import "./Section1.css";

function Section1() {
  return (
    <>
      <div className="Section1MainContainer">
        <div className="Section1Container">
          <div className="div1">
            <div className="imageDiv">
              <img
                className="image1"
                alt="BrandLogo"
                src={require("../../../../assets/HomePage/section1/img2.png")}
              />
            </div>
          </div>
          <div className="div2">
            <div className="sec1Heading">
              <h6>Dream. Design. Enjoy</h6>
              <h2>Welcome to Our Independent Design & Development Agency.</h2>
            </div>
            <div className="sec1Text">
              <p>
                At Navastha Infotech, we specialize in creating exceptional user
                experiences and cutting-edge digital designs that transform your
                vision into reality.
              </p>
              <h4>User Experience</h4>
              <ol>
                <li>
                  Understanding your audience to create designs that resonate
                  and engage.
                </li>
                <li>
                  Building intuitive interfaces that provide seamless navigation
                  and interaction.
                </li>
                <li>
                  Continuously refining designs based on user feedback to
                  enhance satisfaction and performance.
                </li>
              </ol>
              <h4>Digital Design</h4>
              <ol>
                <li>
                  Crafting unique, responsive websites that reflect your brand
                  identity.
                </li>
                <li>
                  Designing intuitive and engaging mobile apps that keep users
                  coming back.
                </li>
                <li>
                  Producing eye-catching graphics that capture attention and
                  communicate your message effectively.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
