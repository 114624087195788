import React from "react";
import "./BrandLogo.css";

function BrandLogo() {
  return (
    <>
      <div className="MainContainerLogo">
        <div className="LogoContainer">
          <div className="brandImgDiv">
            <img
              className="brandImg"
              alt="BrandLogo"
              src={require("../../../../assets/HomePage/brandLogo/dentalKart.jpg")}
            />
          </div>
          <div className="brandImgDiv">
            <img
              className="brandImg"
              alt="BrandLogo"
              src={require("../../../../assets/HomePage/brandLogo/fonbnk.jpg")}
            />
          </div>
          <div className="brandImgDiv">
            <img
              className="brandImg"
              alt="BrandLogo"
              style={{ padding: "0px" }}
              src={require("../../../../assets/HomePage/brandLogo/Vcreatek.jpg")}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandLogo;
