import React, { useState } from "react";
import "./ContactSection2.css";
function ContactSection2() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    window.location.href = `mailto:info@navasthainfotech.com?subject=${formData.message}&body=My name is :${formData.name}, Contact No:${formData.contact} and email id is :${formData.email}.`;
  };

  return (
    <>
      <div className="contact-section">
        <div className="contact-content">
          <h2>Let’s build an awesome project together</h2>
          <p>
            At our IT solution company, we are committed to exceptional customer
            service and support. If you are experiencing technical difficulties
            or need assistance with...
          </p>
          <div className="contact-details">
            <div className="contact-item">
              <div>
                <h5>
                  Address
                  <span className="icon">📍</span>
                </h5>
                <p>
                  100-A, Main, Karni Palace Rd, Hastinapur Colony, Hastinapur
                  Vistar, Vaishali Nagar, Jaipur, Rajasthan 302034
                </p>
              </div>
            </div>
            <div className="contact-item">
              <h5>
                Email
                <span className="icon">📧</span>
              </h5>
              <p>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:info@navasthainfotech.com"
                >
                  info@navasthainfotech.com
                </a>
              </p>
              <div className="contactUsItems">
                <h5>
                  Contact
                  <span className="icon">📞</span>
                </h5>
                <p>
                  <a style={{ textDecoration: "none" }} href="tel:9929572110">
                    9929572110
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form-container">
          <h2>Fill The Contact Form</h2>
          <p>Feel free to contact with us, we don’t spam your email</p>
          <form onSubmit={handleSubmitForm}>
            <div className="form-group">
              <input
                name="name"
                value={formData.name}
                type="text"
                placeholder="Your name"
                onChange={handleChange}
                required
              />
              <input
                name="contact"
                value={formData.contact}
                type="tel"
                maxLength="10"
                pattern="\d{10}"
                title="Please enter exactly 10 digits"
                placeholder="Phone number"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                name="email"
                value={formData.email}
                type="email"
                placeholder="Email address"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                value={formData.message}
                placeholder="Write your message"
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactSection2;
