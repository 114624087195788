import React from "react";
import "./ContactSection1.css";
function ContactSection1() {
  return (
    <>
      <div className="ContactSection1">
        <div className="ContactSec1Content">
          <h1>Let Us Know What You're Looking For</h1>
        </div>
      </div>
    </>
  );
}

export default ContactSection1;
