import React, { useEffect, useState } from "react";
import "./Section3.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Section3() {
  const [slideValue, setSlideValue] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setSlideValue(1);
      } else if (window.innerWidth < 800) {
        setSlideValue(2);
      } else {
        setSlideValue(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="HomeSec3Slide-container">
      <div className="HomeSec3SliderMainDiv">
        <div className="HomeSec3SliderHeading">
          <h2>Recently Completed Projects</h2>
        </div>
        <div className="HomeSec3SliderDiv">
          <Slide
            duration={2000}
            autoplay={true}
            slidesToShow={slideValue}
            className="Homesec3Slider"
          >
            <div className="SliderImgDiv">
              <img
                src={require("../../../../assets/HomePage/section3/dentalKart.jpg")}
                alt={`slide-$}`}
                className="slide-image"
              />
            </div>
            <div className="SliderImgDiv">
              <img
                src={require("../../../../assets/HomePage/section3/fonbnk.jpg")}
                alt={`slide-$}`}
                className="slide-image"
              />
            </div>
            <div className="SliderImgDiv">
              <img
                src={require("../../../../assets/HomePage/section3/Vcreatek.jpg")}
                alt={`slide-$}`}
                className="slide-image"
              />
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default Section3;
