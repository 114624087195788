import React from "react";
import "./App.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Service from "./pages/Services/Service";
import Contact from "./pages/Contact/Contact";
function App() {
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/" />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Blog" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
