import React from "react";
import "./AboutSection7.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function AboutSection7() {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <>
      <section className="section7mainDiv" ref={ref}>
        <div className="numberContainer">
          <div className="numberDiv">
            <div className="countingNumber">
              {inView ? (
                <CountUp end={12000} duration={3} separator="," />
              ) : (
                "0"
              )}
              +
            </div>
            <p>Finished Projects</p>
          </div>
          <div className="numberDiv">
            <div className="countingNumber">
              {inView ? <CountUp end={850} duration={3} separator="," /> : "0"}+
            </div>
            <p>Happy Client</p>
          </div>
          <div className="numberDiv">
            <div className="countingNumber">
              {inView ? <CountUp end={9} duration={3} separator="," /> : "0"}+
            </div>
            <p>Years in Business</p>
          </div>
          <div className="numberDiv">
            <div className="countingNumber">
              {inView ? <CountUp end={36} duration={3} separator="," /> : "0"}+
            </div>
            <p>Experts</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection7;
