import React from "react";
import "./Service.css";
import ServiceSection1 from "../../components/organism/ServiceSection/Section1/ServiceSection1";
import ServiceSection2 from "../../components/organism/ServiceSection/Section2/ServiceSection2";
import ServiceSection3 from "../../components/organism/ServiceSection/Section3/ServiceSection3";
function Service() {
  return (
    <>
      <ServiceSection1 />
      <ServiceSection2 />
      <ServiceSection3 />
    </>
  );
}

export default Service;
