import React from "react";
import "./AboutSection1.css";
function AboutSection1() {
  return (
    <>
      <div className="AboutSection1">
        <div className="AboutSec1Content">
          <div className="AboutSec1">
            <h2>Welcome to Navastha Infotech</h2>
            <h4>Most companies put their values into a manifesto.</h4>
            <h4>
              We put them into action. Here’s what that looks like—from your
              first day to your last day.
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection1;
